import React from "react"

import { Container, Row, Col, Form, FormControl, FormLabel, Button } from "react-bootstrap"
import { Fade } from "react-reveal"

export default class Contact extends React.Component {
	constructor(props) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this)
        this.email = React.createRef()
        this.name = React.createRef()
        this.body = React.createRef()
        this.state = {
            success: null,
            loading: false,
            message: null
        }
	}

    onSubmit(e) {
        e.preventDefault()

        if (this.state.success || this.state.loading) { return false }

        this.setState({ loading: true })

        fetch("https://s45p32ih33.execute-api.eu-west-2.amazonaws.com/dev/", {
            method: "POST",
            body: JSON.stringify({
                "email": this.email.current.value,
                "name": this.name.current.value,
                "body": this.body.current.value
            })
        })
            .then(data => data.json())
            .then(data => {
                this.setState({ loading: false, success: data.success, message: data.message })
            })
    }

	render() {

		return (
			// <ScrollableAnchor id={"contact-us"}>
            <div className="overflow-hidden">
                <Container id="contact-us" className="my-5">
                    <Row>
                        <Col lg="6">
                            <Fade left>
                                <iframe className="rounded-lg w-100" height="500" title="Google Maps" src="https://maps.google.com/maps?q=53.27182642863772,%20-2.116013878903907&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                            </Fade>
                        </Col>
                        <Col lg="6" className="my-auto">
                            <Fade right>
                                <div>
                                    <h2 className="text-center font-weight-bold">Contact Us</h2>
                                    <h4 className="font-weight-light my-3 text-center"><a href="tel:+441625402727" className="text-decoration-none text-light"><i className="fas fa-phone-alt"/> +44 1625 402727</a></h4>
                                    <h4 className="font-weight-light my-3 text-center"><a href="mailto:sales@firelessltd.com" className="text-decoration-none text-light"><i className="fas fa-envelope"/> sales@firelessltd.com</a></h4>
                                    <h4 className="font-weight-light my-3 text-center"><a href="https://goo.gl/maps/DHcfgXMGRUt7xdjP8" target="_blank" rel="noreferrer" className="text-decoration-none text-light"><i className="fas fa-map-pin"/> Silk Point, Queens Avenue, Macclesfield, SK10 2BB</a></h4>
                                    <Form onSubmit={this.onSubmit}>
                                        <Form.Group as={Row}>
                                            <Col>
                                                <FormLabel>Full Name</FormLabel>
                                                <FormControl ref={this.name} type="text" placeholder="John Doe"/>
                                            </Col>
                                            <Col>
                                                <FormLabel>Your Email</FormLabel>
                                                <FormControl ref={this.email} type="email" placeholder="jdoe@example.com"/>
                                            </Col>
                                        </Form.Group>
                                        <FormLabel>Your Message</FormLabel>
                                        <textarea ref={this.body} className="mb-3 form-control" id="exampleFormControlTextarea1" rows="4" style={{ resize: "none" }}></textarea>
                                        { this.state.message && <p className="text-danger">{this.state.message}</p> }
                                        <Button type="submit" className="font-weight-normal px-4" disabled={this.state.loading || this.state.success}>{this.state.loading ? <i className="fas fa-circle-notch fa-spin fa-fw"/> : this.state.success ? <i className="fas fa-check fa-fw"/> : <></>} Sen{this.state.success ? "t" : "d"}</Button>
                                        { this.state.success && <p className="mt-3 text-success">Success! Your message has been sent! Our team will get back to you shortly!</p> }
                                    </Form>
                                </div>
                            </Fade>
                        </Col>
                    </Row>
                </Container>
            </div>
			// </ScrollableAnchor>
		)
	}
}